import NotificationActionPlanSatisfactionPendingTab from '@/components/satisfaction-action-plans/components/notification-action-plan-satisfaction-pending-tab/index.vue';
import NotificationActionPlanSatisfactionAcceptTab from '@/components/satisfaction-action-plans/components/notification-action-plan-satisfaction-accept-tab/index.vue';
import NotificationActionPlanSatisfactionDeclineTab from '@/components/satisfaction-action-plans/components/notification-action-plan-satisfaction-decline-tab/index.vue';

export default {
    name: 'ListActionPlanSatisfactionNotification',
    components: {
        NotificationActionPlanSatisfactionPendingTab,
        NotificationActionPlanSatisfactionAcceptTab,
        NotificationActionPlanSatisfactionDeclineTab
    },

    data() {
        return {
            tab: 0,
            allRequest: false,
        };
    },

    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Notificaciones",
                    link: true,
                    exact: true,
                    disabled: true,
                    to: {
                        //   name: "DashboardActionPlan",
                    },
                },
            ];
        },
    },

    methods: {

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
              (activity) =>
                activity.module_name === module &&
                activity.page_name === page &&
                activity.activity_name === activityName
            );

            this.allRequest = found;

            return found;
          },

    },

    created() {
    },

    mounted() {
        this.isAllowed('climaLaboral','listaSolicitudesActualizacion','listarTodasSolicitudesActualizacion')
    },

    beforeDestroy() {
    },
};