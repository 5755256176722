<template>
	<ApproverSettings />
</template>
<script>

import ApproverSettings from '@/components/action-plans/approver-settings/index.vue'

export default {
	name: 'ApproverSettingsPage',
	components: { ApproverSettings },
};
</script>
