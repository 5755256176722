import { mapActions, mapState } from "vuex";

export default {
  name: "SelectedQuestionsDialog",
  data() {
    return {
      dialog: false,
      loadingSave: false,
      listError: [],
      questionsHeader: [
        { text: "Factores", sortable: false, value: "factor.name" },
        { text: "Subfactores", sortable: false, value: "sub_factor.name" },
        { text: "Preguntas", sortable: false, value: "description" },
        { text: "", sortable: false, value: "actions" },
      ],
      loadingQuestions: false,
      searchQuestion: null,
      filters: {
        description: null,
        service_id: null,
      },
      searchTimeout: null,
    };
  },
  props: {
    value: Array,
    serviceId: Number
  },
  computed: {
    ...mapState("question", ["allQuestions"]),
    selectedQuestions: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() { },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.filters = {
          description: null,
        };
        this.getQuestions();
      }
    },
  },
  methods: {
    ...mapActions("question", ["listAllQuestions"]),
    close() {
      this.dialog = false;
    },
    async deleteItemConfirm() {
      console.log("");
    },

    getQuestions: async function () {
      this.loadingQuestions = true;
      // if (this.serviceId) {
      //   this.filters.service_id = this.serviceId;
      // }
      this.filters.active = true;
      await this.listAllQuestions(this.filters);
      this.loadingQuestions = false;
    },

    isQuestionSelected: function (questionId) {
      return this.selectedQuestions.some((e) => e?.id == questionId);
    },

    addQuestion: function (question) {
      this.selectedQuestions.push(question);
    },

    removeQuestion: function (questionId) {
      const index = this.selectedQuestions.findIndex(
        (e) => e?.id === questionId
      );
      if (index > -1) this.selectedQuestions.splice(index, 1);
    },

    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.filters.description = this.searchQuestion;
        this.getQuestions();
      }, 800);
    },
  },
};
