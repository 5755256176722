import { mapActions, mapState } from "vuex";
import DatesSelectedDialog from "@/components/action-plans/components/selectedDateField/index.vue";
// import QuestionsSelectedDialog from "../components/selectedQuestionsDialog";
import ValidateActivity from '@/components/global/ValidateActivity.vue';
import { showSnackBar } from "@/helpers/globalHelpers";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
  name: "editPeriodoActionPlanSatisfaction",

  data: () => ({
    isLoadingPlanType: false,
    loadingSend: false,
    searchQuestion: "",
    isValidForm: null,
    isLoading: false,
    isLoadingUsers: false,
    isLoadingManagements: false,
    requiredRule: [(v) => !!v || "Campo requerido"],
    selectedManagement: null,
    formUpdatePeriodAP: {
      description: '',
      period: '',
      service_manager: '',
      th_manager: ''
    },
    headersUpdate: [
      { text: "Valor anterior", sortable: false, value: "new_value" },
      { text: "Nuevo valor", sortable: false, value: "old_value" },
      { text: "Propiedad", sortable: false, value: "field" },
      { text: "Estado", sortable: false, value: "status" },
    ],
    headersApprovers: [
      { text: "Usuario", value: "user.fullName", sortable: false },
      { text: "Dimensión", value: "dimension_type", sortable: false },
      { text: "Rol", value: "is_official", sortable: false },
      { text: "Estado", value: "response", sortable: false },
    ],
    headersManagement: [
      { text: "Gerencia", sortable: false, value: "management_name" },
      { text: "Usuario", sortable: false, value: "user_name" },
      { text: "Tipo de Aprobador", sortable: false, value: "role" },
    ],
    headersService: [
      { text: "Servicio", sortable: false, value: "service_name" },
      { text: "Usuario", sortable: false, value: "user_name" },
      { text: "Tipo de Aprobador", sortable: false, value: "role" },
    ],
    headersApproversManagement: [
      { text: "Usuario", sortable: false, value: "response_user.fullName" },
      { text: "Rol", sortable: false, value: "is_official" },
      { text: "Estado", sortable: false, value: "response" },
    ],
    headersApproversService: [
      { text: "Usuario", sortable: false, value: "response_user.fullName" },
      { text: "Rol", sortable: false, value: "is_official" },
      { text: "Estado", sortable: false, value: "response" },
    ],
  }),

  created() {
    // this.loadUsers();
    this.loadEvidenceActionPlan();
    // this.listCostCenter();
    this.getPlanType();
  },

  computed: {
    // ...mapState("security", ["usersActive", "costCenters", "allManagementsState"]),
    ...mapState("action_plan", ["actionPlan"]),
    ...mapState("evidence_action_plan", ["evidenceActionPlan"]),
    ...mapState("plan_type", ["planType"]),

    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidencesSatifaction",
            params:{planTypeId: this.$route.params.planTypeId}
          },
        },
        {
          text: "Editar periodo de plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },

    managementApprovers() {
      if (!this.planType?.modification_approvers) return [];
      return this.planType.modification_approvers
        .map((approver) => approver.management_approver)
        .flatMap((management) => {
          if (!management) return [];
          const approvers = [];
          if (management.user) {
            approvers.push({
              management_name: management.management?.name ?? "N/A",
              user_name: management.user.fullName,
              role: "Aprobador Principal",
              user_id: management.user.id, // <--- importamos el ID
              dimension_type: "MANAGEMENT",
              is_official: true,
            });
          }
          if (management.children) {
            management.children.forEach((child) => {
              if (child.user) {
                approvers.push({
                  management_name: management.management?.name ?? "N/A",
                  user_name: child.user.fullName,
                  role: "Aprobador Secundario",
                  user_id: child.user.id,
                  dimension_type: "MANAGEMENT",
                  is_official: false,
                });
              }
            });
          }
          return approvers;
        });
    },

    serviceApprovers() {
      if (!this.planType?.modification_approvers) return [];
      return this.planType.modification_approvers
        .map((approver) => approver.service_approver)
        .flatMap((service) => {
          if (!service) return [];
          const approvers = [];
          if (service.user) {
            approvers.push({
              service_name: service.service?.name ?? "N/A",
              user_name: service.user.fullName,
              role: "Aprobador Principal",
              user_id: service.user.id,
              dimension_type: "SERVICE",
              is_official: true,
            });
          }
          if (service.children) {
            service.children.forEach((child) => {
              if (child.user) {
                approvers.push({
                  service_name: service.service?.name ?? "N/A",
                  user_name: child.user.fullName,
                  role: "Aprobador Secundario",
                  user_id: child.user.id,
                  dimension_type: "SERVICE",
                  is_official: false,
                });
              }
            });
          }
          return approvers;
        });
    },

    allApprovers() {
      const histories =
        this.evidenceActionPlan.action_plan.action_plan_modification_histories;
      if (!histories.length) return [];
      // Toma el primer (o el último) history
      const firstOrLast = histories[0]; // o histories[histories.length - 1]
      return Array.isArray(firstOrLast.approvers) ? firstOrLast.approvers : [];
    },

    // O 2.1) Filtrar Aprobadores de Gerencia
    managementApproversFilter() {
      return this.allApprovers.filter(
        (ap) => ap.dimension_type === "MANAGEMENT"
      );
    },
    // O 2.2) Filtrar Aprobadores de Servicio
    serviceApproversFilter() {
      return this.allApprovers.filter((ap) => ap.dimension_type === "SERVICE");
    },

    populatedApprovers() {
      if (this.evidenceActionPlan && this.evidenceActionPlan.status === "MODIFICADO") {
        const approversSet = new Set();
        return this.evidenceActionPlan.action_plan.action_plan_modification_histories.reduce((acc, history) => {
          const populatedApprovers = history.approvers.map(approver => {
            const user = this.usersActive.find(user => user.id === approver.response_user_id);
            if (user && !approversSet.has(user.id)) {
              approversSet.add(user.id);
              return {
                ...approver,
                response_user_id: user
              };
            }
            return approver;
          }).filter(approver => approversSet.has(approver.response_user_id.id));
          acc.push(...populatedApprovers);
          return acc;
        }, []);
      }
      return [];
    }
  },

  methods: {
    // ...mapActions("security",
    //   [
    //     "getUsersActive",
    //     "allManagements",
    //   ]),
    ...mapActions('action_plan', [
      'proposalUpdateActionPlan',
      'replyProposalUpdateActionPlan'
    ]),
    ...mapActions('evidence_action_plan',
      [
        'getEvidenceActionPlanById',
        'cleanEvidenceActionPlanId'
      ]),
    
      ...mapActions("plan_type", ["getPlantypeById", "cleanPlanTypeId"]),

      async getPlanType() {
        this.isLoadingPlanType = true;
        const { planTypeId } = this.$route.params;
        const { error } = await this.getPlantypeById(planTypeId);
        if (error) showError(error);
  
        this.isLoadingPlanType = false;
      },


    listCostCenter: async function () {
      const { error } = await this.allManagements({ active: true });
      if (error) showError(error);
    },

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive({ active: 1 });
      this.isLoadingUsers = false;
    },

    loadEvidenceActionPlan: async function () {
      const evidenceActionPlanId = this.$route.params?.evidenceActionPlan;

      if (evidenceActionPlanId) {
        this.isLoading = true;
        const data = await this.getEvidenceActionPlanById(evidenceActionPlanId);
        if (data) {
          this.formUpdatePeriodAP = {
            description: data?.response?.action_plan.description,
            period: moment.utc(data?.response?.register_date).format("YYYY-MM-DD"),
            // service_manager: data?.response?.action_plan?.service?.management_responsible_user_id,
            // th_manager: this.allManagementsState.find(management => management.name === 'GERENCIA TH')?.responsible_id?.id,
          }
        }
        this.isLoading = false;
      }
    },

    saveEvidenceActionPlan: async function () {
      this.loadingSend = true;

        // 1) Tomamos la lista que usas en la tabla
        const mgmtArray = this.managementApprovers; // => algo como [{ management_name, user_name, role }, ...]
        const serviceArray = this.serviceApprovers; // => algo como [{ service_name, user_name, role }, ...]
  
        // 2) Transformar mgmtArray a la estructura que pide la API
        const mgmtApproversForApi = mgmtArray.map((item) => {
          // Identificar is_official
          const isOfficial = item.role === "Aprobador Principal";
          // localizas el userId (aquí asumo que lo tienes en item.user_id,
          // de lo contrario necesitas la lógica para recuperarlo)
          return {
            user_id: item.user_id, // <--- Asegúrate de tener este en tu item
            dimension_type: "MANAGEMENT",
            is_official: isOfficial,
          };
        });
  
        // 3) Transformar serviceArray a la estructura que pide la API
        const serviceApproversForApi = serviceArray.map((item) => {
          const isOfficial = item.role === "Aprobador Principal";
          return {
            user_id: item.user_id, // Mismo comentario: necesitas un user_id real
            dimension_type: "SERVICE",
            is_official: isOfficial,
          };
        });
  
        // 4) Combinas ambos
        const allApprovers = [...mgmtApproversForApi, ...serviceApproversForApi];

      let data = {
        description: this.formUpdatePeriodAP.description,
        approvers: allApprovers,

        evidences_action_plan: [
          {
            evidence_action_plan_id: this.$route.params?.evidenceActionPlan,
            register_date: this.formUpdatePeriodAP?.period,
          }
        ],

        comment: null,
      };

      //[TODO*]habilitar la nueva api
      const { ok, error } = await this.proposalUpdateActionPlan({
        id: this.evidenceActionPlan.action_plan.id,
        payload: data,
      });
      if (ok) {
        this.$swal.fire({
          title: "Solicitud de actualización creada con éxito",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });
        this.formUpdatePeriodAP = {
          description: '',
          period: '',
          service_manager: '',
          th_manager: ''
        }
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    },

    cancelUpdateEvidenceActionPlan: async function () {
      this.formUpdatePeriodAP = {
        description: '',
        period: '',
        service_manager: '',
        th_manager: ''
      },
        this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });
    },

    isAllowed(module, page, activityName) {

      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );

      return found;
    },

    replyUpdate: async function (confirmed) {
      this.loadingSend = true;
      const { evidenceActionPlan } = this.$route.params;

      let data = {
        confirm: confirmed,
        evidence_action_plan_id:evidenceActionPlan
      };
      
      const { error } = await this.replyProposalUpdateActionPlan({
        id: this.evidenceActionPlan.action_plan.id,
        payload: data,
      });

      let errorMsm = error?.data?.message;
      errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
      showSnackBar(errorMsm, "error");
      this.loadingSend = false;
      this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });

    },
  },

  beforeDestroy() {
    this.cleanEvidenceActionPlanId();
  },

  components: {
    DatesSelectedDialog,
    // QuestionsSelectedDialog,
    ValidateActivity
  },
};
