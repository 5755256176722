<template>
	<ExportReportActionPlanSatisfaction />
</template>
<script>

import ExportReportActionPlanSatisfaction from '@/components/satisfaction-action-plans/export-report-action-plan/index.vue'

export default {
	name: 'ExportReportActionPlanSatisfactionPage',
	components: { ExportReportActionPlanSatisfaction },
};
</script>
