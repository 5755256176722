import { mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
    name: 'ActionPlanSatsifactionNotificationAcceptTab',
    components: {
    },

    props:{
        allRequests:Boolean,
    },


    data() {
        return {
            isLoading: false,
            notificationAccept: [],
            notificationPagination: null,
            notificationFilter: {
                type: 'SATISFACCION_CLIENTE',
                status: 'ACEPTADO',
                page:1,
                limit: 20 || process.env.VUE_APP_PAGINATION_PER_PAGE,
                pagination: true,
            },
            headers: [
                { text: 'Aprobador', sortable: false, value: 'response_user.fullName' },
                { text: 'Fecha de aprobación', sortable: false, value: 'response_date' },
                { text: 'Estado', sortable: false, value: 'response' }
            ],
            headersValues: [
                { text: 'Campo', sortable: false, value: 'field' },
                { text: 'Antiguo valor', sortable: false, value: 'old_value' },
                { text: 'Nuevo valor', sortable: false, value: 'new_value' },
            ]
        };
    },

    computed: {
    },

    methods: {
        ...mapActions('action_plan', ['listActionPlanUpdatePagination']),

        async getNotificationAccept() {
            this.isLoading = true;
            if(this.allRequests) {
                this.notificationFilter.allRequests = this.allRequests;
            }
            const { error, response } = await this.listActionPlanUpdatePagination(this.notificationFilter);
            if (error) showError(error);
            this.notificationAccept = response.data;
            this.notificationPagination = response.pagination;
            this.isLoading = false;
        },

        formatDate(dateString) {

            if (!dateString) {
                return "-";
            }
            const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

            return dateOnly;
        },

        getApprovers(notification) {
            if (!notification || !notification.modification_histories || notification.modification_histories.length === 0) {
                return [];
            }

            const firstHistory = notification.modification_histories[0];
            return firstHistory?.approvers || [];
        },

        setPropertyName(item) {
            switch (item) {
                case 'description':
                    return 'Descripción'
                case 'register_date':
                    return 'Fecha de registro'
            }
        }

    },

    created() {
        this.getNotificationAccept();
    },

    mounted() {
    },

    beforeDestroy() {
    },
};