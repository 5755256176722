import DragDropFile from "@/components/global/DragDropFile.vue";
import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";
export default {
  name: "DetailActionPlanSatisFaction",

  data() {
    return {
      isLoadingPlanType: false,
      isLoading: false,
      tab: 0,
      periodHeaders: [
        { text: "Estado", sortable: false, value: "status" },
        { text: "Fecha de periodo", sortable: false, value: "register_date" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      reportHeaders: [
        { text: "Año", sortable: false, value: "period" },
        { text: "Mes", sortable: false, value: "period_month" },
        {
          text: "Tareas programadas",
          sortable: false,
          value: "scheduledTasks",
        },
        { text: "Tareas ejecutadas", sortable: false, value: "executedTasks" },
        { text: "% de avance", sortable: false, value: "progress" },
      ],
      additionalFields: [
        { text: "Nombre de campo", sortable: false, value: "field_name" },
        { text: "Valor de campo", sortable: false, value: "field_value" },
      ],
      // Tus datos aquí
      evidences: [],
      years: [],
      filterYear: "",
      filterMonth: "",
      months: [
        { name: "ENERO", value: "January" },
        { name: "FEBRERO", value: "February" },
        { name: "MARZO", value: "March" },
        { name: "ABRIL", value: "April" },
        { name: "MAYO", value: "May" },
        { name: "JUNIO", value: "June" },
        { name: "JULIO", value: "July" },
        { name: "AGOSTO", value: "August" },
        { name: "SETIEMBRE", value: "September" },
        { name: "OCTUBRE", value: "October" },
        { name: "NOVIEMBRE", value: "November" },
        { name: "DICIEMBRE", value: "December" },
      ],
    };
  },

  computed: {
    profiles() {
      // let permissions = [];
      const companies = this.$store.state.auth.user?.companies || [];
      const profiles = []
      if (Array.isArray(companies)) {
        companies.forEach((company) => {
          company.profiles.forEach((profile) => {
            profiles.push(profile.role)
            // profile.modules.forEach((module) => {
            // 	module.pages.forEach((page) => {
            // 		page.activities.forEach((activity) => {
            // 			permissions.push({
            // 				company_name: company?.name,
            // 				profile_name: profile?.role?.name,
            // 				module_name: module?.name,
            // 				page_name: page?.name,
            // 				activity_name: activity?.name,
            // 			});
            // 		});
            // 	});
            // });
          });
        });
      }
      return profiles || [];
    },

    ...mapState("plan_type", ["planType"]),

    isAuthorized() {
      const planTypeProfiles = this.planType?.profiles?.map(
        (profile) => profile.profile.id
      );

      return this.profiles?.some((profile) =>
        planTypeProfiles?.includes(profile.id)
      );
    },

    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Lista de planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ReportActionPlansDinamic",
            planTypeId: this.$route.params.planTypeId,
          },
        },
        {
          text: "Detalle de agrupación de planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "DetailActionPlanGroupedServiceDinamic",
            params: { serviceId: this.$route?.params.serviceId , planTypeId: this.$route.params.planTypeId}
          },
        },
        {
          text: "Detalle de plan de acción",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          //   name: 'DetailActionPlanSatisfaction',
          //   params: { serviceId: this.$route.params.serviceId, actionPlanId: this.$route.params.actionPlanId }
          // },
        },
      ];
    },
    ...mapState("action_plan", ["actionPlan"]),
    prepareFilterData() {

      const yearsSet = new Set(
        this.actionPlan?.generalProgress.monthlyProgress.map(
          (item) => item.period
        )
      );
      return (this.years = [...yearsSet]);
    },
    filteredMonthlyProgress() {

      // Verifica si ambos filtros están vacíos, y en ese caso, devuelve todos los datos.
      if (!this.filterYear && !this.filterMonth) {
        return this.actionPlan?.generalProgress?.monthlyProgress || [];
      }

      return (this.actionPlan?.generalProgress?.monthlyProgress || []).filter(item => {
        const matchesYear = this.filterYear ? item.period === this.filterYear : true;
        const matchesMonth = this.filterMonth ? item.period_month === this.filterMonth : true;
        return matchesYear && matchesMonth;
      });
    }
  },

  created() {
    this.getPlanType();
    this.getActionPlan();
  },

  methods: {
    // Tus métodos aquí
    ...mapActions("action_plan", ["getActionPlanById", "cleanActionPlanId"]),
    ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

    async getPlanType() {
      this.isLoadingPlanType = true;
      const { planTypeId } = this.$route.params;
      const { error } = await this.getPlantypeById(planTypeId)
      if (error) showError(error);

      if (!this.isAuthorized) {
        this.$router.push({ name: "NotAuthorized" });
      }

      this.isLoadingPlanType = false;
    },

    async getActionPlan() {
      this.isLoading = true;
      const { actionPlanId } = this.$route.params;
      const { error } = await this.getActionPlanById(actionPlanId);
      if (error) showError(error);
      this.isLoading = false;
    },

    setColorStatusActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "ATRASADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: status,
          };
        case "ANULADO":
          return {
            background: "grey lighten-2",
            color: "grey",
            name: status,
          };
      }
    },

    setColorStatusEvidenceActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "ATRASADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: status,
          };
        case "NO_COMPLETADO":
          return {
            background: "grey lighten-2",
            color: "grey",
            name: "NO COMPLETADO",
          };
        case "PENDIENTE":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "PENDIENTE",
          };
        case "OBSERVADO":
          return {
            background: "yellow lighten-4",
            color: "yellow darken-2",
            name: "OBSERVADO",
          };
      }
    },

    setNameMonth(month) {
      switch (month) {
        case 'January':
          return 'Enero'
        case 'February':
          return 'Febrero'
        case 'March':
          return 'Marzo'
        case 'April':
          return 'Abril'
        case 'May':
          return 'Mayo'
        case 'June':
          return 'Junio'
        case 'July':
          return 'Julio'
        case 'August':
          return 'Agosto'
        case 'September':
          return 'Setiembre'
        case 'October':
          return 'Octubre'
        case 'November':
          return 'Noviembre'
        case 'December':
          return 'Diciembre'
      }
    },

    formatDate(dateString) {
      // if (!dateString) {
      //   return { dateOnly: "-", isToday: false };
      // }
      // const formattedDateTime = moment(dateString).format("DD/MM/YYYY HH:mm");
      // const dateOnly = formattedDateTime.split(" ")[0];
      // const isToday = moment().format("DD/MM/YYYY") === dateOnly; // Comparar con la fecha actual

      // return { dateOnly, isToday };

      if (!dateString) {
        return "-";
      }
      // Trata la fecha como un objeto de fecha pura sin conversión de zona horaria
      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },
  },

  beforeDestroy() {
    this.cleanActionPlanId();
  },

  components: {
    DragDropFile,
  },
};
