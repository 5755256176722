import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";
export default {
    name: "ReportActionPlanDinamic",
    data() {
        return {
            isLoadingPlanType: false,
            loadingActionPlan: false,
            isLoadingUsers: false,
            itemsPerPage: 10,
            monthValues: [{ name: "ENERO", value: 1 },
            { name: "FEBRERO", value: 2 },
            { name: "MARZO", value: 3 },
            { name: "ABRIL", value: 4 },
            { name: "MAYO", value: 5 },
            { name: "JUNIO", value: 6 },
            { name: "JULIO", value: 7 },
            { name: "AGOSTO", value: 8 },
            { name: "SETIEMBRE", value: 9 },
            { name: "OCTUBRE", value: 10 },
            { name: "NOVIEMBRE", value: 11 },
            { name: "DICIEMBRE", value: 12 }],
            headers: [
                { text: "Servicio", sortable: false, value: "service.name" },
                { text: "Gerencia", sortable: false, value: "management.name" },
                { text: "Tareas programadas anuales", sortable: false, value: "generalProgress.totalScheduledTasks" },
                { text: "Tareas ejecutadas anuales", sortable: false, value: "generalProgress.totalExecutedTasks" },
                { text: "% de avance anual", sortable: false, value: "generalProgress.progress" },
                { text: "Tareas programadas por mes", sortable: false, value: "generalProgress.monthScheduledTasks" },
                { text: "Tareas ejecutadas por mes", sortable: false, value: "generalProgress.monthExecutedTasks" },
                { text: "% de avance por mes", sortable: false, value: "generalProgress.monthProgress" },
                { text: "Acciones", value: "actions", sortable: false },
            ],
            rowsPerPage: [{ value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }],
            loadingExportActionPlan: false,
            searchTimeout: null,
            searchValue: "",
            reportActionFilters: {
                page: 1,
                limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
                pagination: true,
                plan_type_id: this.$route.params.planTypeId,
                //
                service_ids: [],
                management_id: null,
                month: parseInt(moment().format('M')),
                year: parseInt(moment().format('Y')),
            },
            serviceId: null,
            //cabeceras del primer data table, value nombre de la prop!
            headersReport: [
                { text: "N° Acciones Programadas hasta mes seleccionado", sortable: false, value: "totalScheduledTasks" },
                { text: "N° Acciones Ejecutadas hasta mes seleccionado", sortable: false, value: "totalExecutedTasks" },
                { text: "% Cumplimiento al mes seleccionado", sortable: false, value: "compliance" },
                { text: "N° Acciones programadas anuales", sortable: false, value: "totalScheduledTasksPerYear" },
                { text: "% Programado hasta mes seleccionado vs anual", sortable: false, value: "plannedCompliance" },
                { text: "% Ejecutado hasta mes seleccionado vs anual", sortable: false, value: "realCompliance" },
            ],

            //cabcereas del 2do data table
            headersMonthReport: [
                { text: "Mes", sortable: false, value: "month" },
                { text: "Programadas", sortable: false, value: "scheduledTasks" },
                { text: "Ejecutadas", sortable: false, value: "executedTasks" },
                { text: "Porcentaje de avance por mes", sortable: false, value: "progress" },
            ],

            yearValues: [
                { year: 2023 },
                { year: 2024 },
                { year: 2025 },
                { year: 2026 },
              ],

            filteredCostCenters: [],
        };
    },

    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Lista de planes de acción por servicio",
                    link: true,
                    exact: true,
                    disabled: true,
                    // to: {
                    //   name: "HomeActionPlans",
                    // },
                },
            ];
        },
        ...mapState("auth", ["user"]),
        ...mapState("security", [
            "usersActive",
            "costCentersUser",
            "managementsUser"
        ]),
        ...mapState("action_plan", [
            // "actionPlansGroupedByService",
            "actionPlanReport"
        ]),

        itemsHeaderReport() {
            if (!this.actionPlanReport) return [];

            return [
                {
                    totalScheduledTasks: this.actionPlanReport.totalScheduledTasksUntilSelectedMonth,
                    totalExecutedTasks: this.actionPlanReport.totalExecutedTasksUntilSelectedMonth,
                    compliance: `${parseFloat(this.actionPlanReport.complianceUntilSelectedMonth).toFixed(2)}%`,
                    totalScheduledTasksPerYear: this.actionPlanReport.totalScheduledTasksPerYear,
                    plannedCompliance: `${parseFloat(this.actionPlanReport.plannedAnnualCompliance).toFixed(2)}%`,
                    realCompliance: `${parseFloat(this.actionPlanReport.realAnnualCompliance).toFixed(2)}%`
                }
            ];
        },

        itemsHeadersMonthReport() {
            if (!this.actionPlanReport) return [];

            return this.actionPlanReport?.monthlyProgress?.map(m => {
                return {
                    month: m.month.toLocaleUpperCase(),
                    scheduledTasks: m.scheduledTasks,
                    executedTasks: m.executedTasks,
                    progress: `${parseFloat(m.progress).toFixed(2)}%`,
                }
            })
        },

        profiles() {
            // let permissions = [];
            const companies = this.$store.state.auth.user?.companies || [];
            const profiles = []
            if (Array.isArray(companies)) {
                companies.forEach((company) => {
                    company.profiles.forEach((profile) => {
                        profiles.push(profile.role)
                        // profile.modules.forEach((module) => {
                        // 	module.pages.forEach((page) => {
                        // 		page.activities.forEach((activity) => {
                        // 			permissions.push({
                        // 				company_name: company?.name,
                        // 				profile_name: profile?.role?.name,
                        // 				module_name: module?.name,
                        // 				page_name: page?.name,
                        // 				activity_name: activity?.name,
                        // 			});
                        // 		});
                        // 	});
                        // });
                    });
                });
            }
            return profiles || [];
        },

        ...mapState("plan_type", ["planType"]),

        isAuthorized() {
            const planTypeProfiles = this.planType?.profiles?.map(
                (profile) => profile.profile.id
            );

            return this.profiles?.some((profile) =>
                planTypeProfiles?.includes(profile.id)
            );
        },
    },

    watch: {
        'reportActionFilters.month'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateReportURL();
            }
        },
        'reportActionFilters.management_id'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateReportURL();
            }
        },
    },

    created() {
        this.initializeComponent();
        this.getPlanType();
    },

    methods: {
        ...mapActions("security", [
            // "getManagements", 
            "getCostCenterUser",
            "getUsersActive"
        ]),
        ...mapActions("action_plan", [
            "downloadExportActionPlan",
            "getActionPlanReport"
        ]),

        ...mapActions('plan_type', ['getPlantypeById', 'cleanPlanTypeId']),

        async getPlanType() {
            this.isLoadingPlanType = true;
            const { planTypeId } = this.$route.params;
            const { error } = await this.getPlantypeById(planTypeId)
            if (error) showError(error);

            if (!this.isAuthorized) {
                this.$router.push({ name: "NotAuthorized" });
            }

            this.isLoadingPlanType = false;
        },

        async initializeComponent() {
            await this.getCostCenterByUser();

            if (this.$route.query.management_id || this.$route.query.month) {
                this.initializeFiltersFromQuery();
                this.filterCostCenters();
            } else if (this.managementsUser && this.managementsUser.length > 0) {
                this.reportActionFilters.management_id = this.managementsUser[0].id;
                this.filterCostCenters();
            } else {
                this.listActionPlanReport();
            }
        },

        initializeFiltersFromQuery() {
            const month = this.$route.query.month;
            const management_id = this.$route.query.management_id;

            if (month !== undefined) {
                this.reportActionFilters.month = parseInt(month);
            }
            if (management_id !== undefined) {
                this.reportActionFilters.management_id = parseInt(management_id);
            }
        },

        filterCostCenters() {
            if (this.reportActionFilters.management_id) {
                const management = this.managementsUser?.find(m => m.id === this.reportActionFilters.management_id);
                this.filteredCostCenters = management
                    ? management.costCenters.filter(
                        (costCenter, index, self) =>
                            index === self.findIndex((c) => c.id === costCenter.id)
                    )
                    : [];

                if (this.filteredCostCenters.length > 0) {
                    this.reportActionFilters.service_ids = this.filteredCostCenters.map(costCenter => costCenter.id);
                    this.serviceId = this.filteredCostCenters[0].id;
                } else {
                    this.reportActionFilters.service_ids = [];
                    this.serviceId = null;
                }
            } else {
                this.filteredCostCenters = this.allCostCenters;
                this.reportActionFilters.service_ids = [];
                this.serviceId = null;
            }

            this.listActionPlanReport();
        },

        async getCostCenterByUser() {
            this.loadingCostCenter = true;
            const { error } = await this.getCostCenterUser({
                user_id: this.user.id,
            });
            if (error) showError(error);
            this.loadingCostCenter = false;
        },

        async listActionPlanReport() {
            this.loadingActionPlan = true;

            const filters = { ...this.reportActionFilters };

            if (filters.service_ids && filters.service_ids.length > 0) {
                filters.service_ids = filters.service_ids.join(',');
            } else {
                delete filters.service_ids;
            }

            const { error } = await this.getActionPlanReport(filters);
            if (error) showError(error);
            this.loadingActionPlan = false;
        },

        setColorProrgressActionPlanAgroupedByService(progress) {
            if (progress <= 60.9) {
                return {
                    background: "red lighten-4",
                    color: "red",
                    name: `${progress} %`,
                };
            } else if (progress <= 74.9 && progress >= 61) {
                return {
                    background: "yellow lighten-3",
                    color: "yellow darken-3",
                    name: `${progress} %`,
                };
            } else {
                return {
                    background: "green lighten-4",
                    color: "green",
                    name: `${progress} %`,
                };
            }

        },

        async downloadExportActionPlansService() {
            this.loadingExportActionPlan = true;
            const filters = {
                plan_type_id : this.planType?.id,
                year : this.reportActionFilters.year
              }
            //llamada de action
            const { error } = await this.downloadExportActionPlan(filters);
            if (error) showError(error);
            this.loadingExportActionPlan = false;
        },

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
                (activity) =>
                    activity.module_name === module &&
                    activity.page_name === page &&
                    activity.activity_name === activityName
            );

            return found;
        },

        redirectToDetail() {
            if (this.serviceId) {
                this.$router.push({
                    name: 'DetailActionPlanGroupedServiceDinamic',
                    params: {
                        serviceId: this.reportActionFilters.service_ids[0] || this.serviceId,
                        plantypeId: this.planType.id
                    }
                });
            } else {
                console.error('serviceId is not defined');
            }
        },

        redirectToDashboard() {
            this.$router.push({
                name: 'DashboardActionPlanSatisfaction',
                query: {
                    selectedMonth: this.reportActionFilters.month,
                    selectedManagement: this.reportActionFilters.management_id
                }
            });
        },

        updateReportURL() {
            if (this.$route.name === 'ReportActionPlans') {
                this.$router.push({
                    query: {
                        month: this.reportActionFilters.month,
                        management_id: this.reportActionFilters.management_id || undefined
                    }
                }).catch(err => {
                    if (err.name !== "NavigationDuplicated") {
                        console.error(err);
                    }
                });
            }
        },

    },

    beforeDestroy() {
        // this.cleanActionPlanGroupedService();
    }
}