import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import ExportReportDialog from "@/components/action-plans/components/registerExportReportModal/index.vue";

export default {
  name: "BasicComponent",

  components: {
    ExportReportDialog,
  },

  data() {
    return {
      loadingReports: {},
      isLoadingPlanType: false,
      loadingExportReportActionPlan: false,
      headersExportReport: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "Descripción", sortable: false, value: "description" },
        { text: "Año", sortable: false, value: "year" },
        {
          text: "Campos seleccionados",
          sortable: false,
          value: "reportHeaders",
        },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      exportReportFilter: {
        page: 1,
        limit: 20 || process.env.VUE_APP_PAGINATION_PER_PAGE,
        pagination: true,
      },
      modalOpen: false,
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Informe de planes de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },
    ...mapState("plan_type", ["planType"]),
    ...mapState("evidence_action_plan", [
      "exportReportActionPlan",
      "exportReportActionPlanPagination",
    ]),
  },

  created() {
    this.getPlanType();
    this.getExportReportActionPlan();
  },

  methods: {
    ...mapActions("plan_type", ["getPlantypeById", "cleanPlanTypeId"]),

    ...mapActions("evidence_action_plan", [
      "listExportReportActionPlanPagination",
      "registerExportReportActionPlan",
      "getExportReportId",
    ]),
    async getPlanType() {
      this.isLoadingPlanType = true;
      const { planTypeId } = this.$route.params;
      const { error } = await this.getPlantypeById(planTypeId);
      if (error) showError(error);

      this.isLoadingPlanType = false;
    },

    async getExportReportActionPlan() {
      this.loadingExportReportActionPlan = true;
      const { planTypeId } = this.$route.params;
      this.exportReportFilter = {
        ...this.exportReportFilter,
        ...{ plan_type_id: planTypeId },
      };
      const { error } = await this.listExportReportActionPlanPagination(
        this.exportReportFilter
      );
      if (error) showError(error);
      this.loadingExportReportActionPlan = false;
    },

    async downloadReport(item) {
      try {
        this.$set(this.loadingReports, item.id, true);
    
        const { error, ok } = await this.getExportReportId({ id: item.id });
    
        if (error) {
          showError("Error al descargar el informe: " + error);
          return;
        }
    
        if (ok) {
          console.log("Descarga iniciada correctamente.");
        } else {
          showError("No se pudo generar el archivo de descarga.");
        }
      } catch (err) {
        showError("Ocurrió un error inesperado al descargar el informe.");
      } finally {
        this.$set(this.loadingReports, item.id, false);
      }
    },

    translateHeader(headerName) {
      const staticHeaderMap = {
        id: "ID de Evidencia",
        register_date: "Fecha de subida de evidencia",
        action_plan_description: "Descripción",
        recipients: "Usuarios a Notificar",
        service: "Servicio",
        action_plan_details: "Preguntas",
        satisfaction_factors: "Factores",
      };

      return staticHeaderMap[headerName] || headerName;
    },
  },

  beforeDestroy() {},
};
