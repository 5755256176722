import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import { Bar } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { showSnackBar } from "@/helpers/globalHelpers";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: "DetailPeriodUpdateRequest",
  data() {return {
    panel: [0, 1, 2, 3],
    readonly: false,
    filteredCostCenters: [],
    tab: 0,
    chartData1: {
      labels: [],
      datasets: [],
    },
    chartData2: {
      labels: [],
      datasets: [],
    },
    chartData3: {
      labels: [],
      datasets: [],
    },
    chartData4: {
      labels: [],
      datasets: [],
    },
    chartOptions1: {
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback(value) {
              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: true,
          position: "bottom",
        },
        datalabels: {
          order: 60,
          display: true,
          color: "#000",
          font: {
            weight: "bold",
            size: 20,
          },
          align: "end",
          anchor: "end",
          offset: 5,
          formatter: (value) => {
            return value + "%";
          },
        },
        whiteBackground: {},
        title: {
          display: true,
          text: `Gráficos y métricas de cumplimiento por gerencia al mes seleccionado`,
          font: {
            size: 22,
            weight: "bold",
          },
          color: "#000",
          padding: {
            bottom: 50,
          },
        },
      },
    },
    chartOptions2: {
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback(value) {
              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: true,
          position: "bottom",
        },
        datalabels: {
          display: true,
          color: "#000",
          align: "end",
          anchor: "end",
          offset: 5,
          font: {
            weight: "bold",
            size: 20,
          },
          formatter(value) {
            return value + "%";
          },
        },
        whiteBackground: {},
        title: {
          display: true,
          text: "Gráficos y métricas de cumplimiento planificado vs real",
          font: {
            size: 22,
            weight: "bold",
          },
          color: "#000",
          padding: {
            bottom: 50,
          },
        },
      },
    },
    chartOptions3: {
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback(value) {
              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: true,
          position: "bottom",
        },
        datalabels: {
          order: 60,
          display: true,
          color: "#000",
          font: {
            weight: "bold",
            size: 20,
          },
          align: "end",
          anchor: "end",
          offset: 5,
          formatter: (value) => {
            return value + "%";
          },
        },
        whiteBackground: {},
        title: {
          display: true,
          text: "Gráficos y métricas de Servicios",
          font: {
            size: 22,
            weight: "bold",
          },
          color: "#000",
          padding: {
            bottom: 50,
          },
        },
      },
    },
    chartOptions4: {
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback(value) {
              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: true,
          position: "bottom",
        },
        datalabels: {
          display: true,
          color: "#000",
          align: "end",
          anchor: "end",
          offset: 5,
          font: {
            weight: "bold",
            size: 20,
          },
          formatter(value) {
            return value + "%";
          },
        },
        whiteBackground: {},
        title: {
          display: true,
          text: "Gráficos y métricas de servicios asociado a la gerencia",
          font: {
            size: 22,
            weight: "bold",
          },
          color: "#000",
          padding: {
            bottom: 50,
          },
        },
        customAnnotations: {
          totalAmounts: [],
        },
      },
    },
    isLoadingData: false,
    months: [
      { name: "Enero", value: 1 },
      { name: "Febrero", value: 2 },
      { name: "Marzo", value: 3 },
      { name: "Abril", value: 4 },
      { name: "Mayo", value: 5 },
      { name: "Junio", value: 6 },
      { name: "Julio", value: 7 },
      { name: "Agosto", value: 8 },
      { name: "Septiembre", value: 9 },
      { name: "Octubre", value: 10 },
      { name: "Noviembre", value: 11 },
      { name: "Diciembre", value: 12 },
    ],
    managementTypes: [
      { name: "Gerencia", value: "management" },
      { name: "Area", value: "area" },
    ],
    yearValues: [
      { year: 2023 },
      { year: 2024 },
      { year: 2025 },
      { year: 2026 },
    ],
    currentMonth: new Date().getMonth() + 1,
    selectedMonth: parseInt(moment().format("M")),
    selectedManagement: null,
    selectedService: null,
    selectedManagementType: null,
    selectedYear: parseInt(moment().format("Y")),
    filteredManagements: [],
    chartTitle1 : 'Gráficos y métricas de cumplimiento por gerencia al mes seleccionado',
    chartTitle2 : 'Gráficos y métricas de cumplimiento planificado vs real',
    chartTitle3 : 'Gráficos y métricas de Servicios',
    chartTitle4 : 'Gráficos y métricas de Servicios asociado la gerencia'
  }},

  watch: {
    selectedMonth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateURL();
      }
    },
    selectedManagement(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateURL();
      }
    },
  },

  created() {
    this.getCostCenterByUser();
    this.initializeFiltersFromQuery();
    this.addCustomPlugin();
  },

  computed: {
    headers() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || "";
      return [
        { text: "Servicio", value: "serviceName" },
        {
          text: `N° de acciones programadas Enero - ${monthName}`,
          value: "tasksUntilSelectedMonth",
        },
        {
          text: `N° de acciones ejecutadas Enero - ${monthName}`,
          value: "completedTasksUntilSelectedMonth",
        },
        { text: "% de cumplimiento", value: "advancePercentageService" },
        { text: "Plan de acción anual totales", value: "totalAnnualTasks" },
        {
          text: `% programado Enero - ${monthName}`,
          value: "completionPercentage",
        },
        {
          text: `% ejecutado Enero - ${monthName}`,
          value: "executionPercentage",
        },
        
      ];
    },
    serviceComplianceHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || "";
      return [
        { text: "Nombre del Servicio", value: "serviceName" },
        {
          text: `Total Acciones Ejecutadas (Enero - ${monthName})`,
          value: "completedTasks",
        },
        {
          text: `Porcentaje de Cumplimiento (Enero - ${monthName})`,
          value: "completionPercentage",
        },
      ];
    },
    complianceByManagementHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || "";
      return [
        { text: "Gerencia", value: "managementName" },
        {
          text: `N° acciones ejecutadas Enero - ${monthName}`,
          value: "totalCompleted",
        },
        {
          text: `% de cumplimiento Enero - ${monthName}`,
          value: "overallCompletionPercentage",
        },
      ];
    },
    plannedVsRealComplianceHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || "";
      return [
        { text: "Gerencia", value: "managementName" },
        {
          text: `N° de acciones programadas Enero - ${monthName}`,
          value: "tasksUntilSelectedMonth",
        },
        {
          text: `N° de acciones ejecutadas Enero - ${monthName}`,
          value: "completedTasksUntilSelectedMonth",
        },
        { text: "% de cumplimiento", value: "advancePercentageManagement" },
        { text: "Plan de acción anual totales", value: "totalAnnualTasks" },
        {
          text: `% programado Enero - ${monthName}`,
          value: "plannedCompletionPercentage",
        },       
        {
          text: `% ejecutado Enero - ${monthName}`,
          value: "realCompletionPercentage",
        },
        
      ];
    },
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Graficos",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },
    selectedMonthName() {
      return this.months.find(m => m.value === this.selectedMonth)?.name || "";
    },
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      "managementsUser",
      "allCostCenters",
      "allManagementsState",
    ]),
    ...mapState("action_plan", ["reportDashboardData"]),
  },
  methods: {
    ...mapActions("action_plan", ["getReportDashboardData"]),
    ...mapActions("security", ["getCostCenterUser", "allManagements"]),

    initializeFiltersFromQuery() {
      const month = this.$route.query.selectedMonth;

      if (month !== undefined) {
        this.selectedMonth = parseInt(month);
      }
    },

    async loadData() {
      this.isLoadingData = true;
      await this.getReportData();
      this.isLoadingData = false;
    },

    async getCostCenterByUser() {
      const { error } = await this.allManagements({ active: true });
      if (error) {
        showError(error);
      } else {
        this.selectedManagementType = "area";

        const management_id = this.$route.query.selectedManagement;

        if (management_id !== undefined) {
          const foundManagementFilter = this.allManagementsState.find(
            (management) => management.id === parseInt(management_id)
          );

          if (foundManagementFilter) {
            this.selectedManagementType = foundManagementFilter.management_type;
          }
        }

        this.updateManagementType();
      }
    },

    filterCostCenters() {
      if (this.selectedManagement) {
        const management = this.managementsUser.find(
          (m) => m.id === this.selectedManagement
        );
        this.filteredCostCenters = management ? management.costCenters : [];
      } else {
        this.filteredCostCenters = this.allCostCenters;
      }
    },

    // async getReportData() {
    //   this.loadingActionPlan = true;
    //   const data = await this.getReportDashboardData({
    //     month: this.selectedMonth,
    //     management_id: this.selectedManagement,
    //     service_id: this.selectedService,
    //     management_type: this.selectedManagementType,
    //     year: this.selectedYear,
    //     type: "CLIMA_LABORAL",
    //   });

    //   if (!data.ok) showError(data.error);
    //   this.processReportData(data.response);
    //   this.loadingActionPlan = false;
    // },

        async getReportData() {
      this.loadingActionPlan = true;
      const data = await this.getReportDashboardData({
        month: this.selectedMonth,
        management_id: this.selectedManagement,
        service_id: this.selectedService,
        management_type: this.selectedManagementType,
        type:'SATISFACCION_CLIENTE'
      });

      if (!data.ok) showError(data.error);
      this.processReportData(data.response);
      this.loadingActionPlan = false;
    },

    roundPercentage(value) {
      return value !== null && value !== undefined ? Math.round(value) : "-";
    },

    getCompletionColor(percentage) {
      if (percentage === 100) return "success";
      if (percentage >= 51) return "yellow accent-4";
      return "error";
    },

    processReportData(data) {
      /**
       * First chart
       */
      const generalManagements =
        data.DashboardComplianceByManagementForSelectedMonth;
      const generalLabels = generalManagements.map(
        (item) => item.managementName
      );
      const splittedGeneralManagements = generalLabels.map((name) =>
        this.autoSplitLabel(name, 13)
      );
      const overallCompletionPercentage = generalManagements.map(
        (item) => Math.round(item.overallCompletionPercentage)
      );
      
      const totalCompleted = generalManagements.map(
        (item) => Math.round(item.totalCompleted)
      );

      this.chartData1 = {
        labels: splittedGeneralManagements,
        datasets: [
          {
            label: `% cumplimiento Enero - ${
              this.months[this.selectedMonth - 1]?.name
            }`,
            backgroundColor: "#00bcd4",
            data: overallCompletionPercentage,
            rawAmounts: totalCompleted,
          },
        ],
      };

      /**
       * second chart
       */
      const plannedRealComplieance = data.DashboardPlannedVsRealCompliance;
      const managementNames = plannedRealComplieance.map(
        (item) => item.managementName
      );
      const splittedManagementNames = managementNames.map((name) =>
        this.autoSplitLabel(name, 13,true)
      );
      const chartTwoTotalAnnualTasks = plannedRealComplieance.map(item => item.totalAnnualTasks);
      const chartTwotasksUntilSelectedMonth = plannedRealComplieance.map(
        (item) => Math.round(item.tasksUntilSelectedMonth)
      );

      const chartTwoplannedCompletionPercentage = plannedRealComplieance.map(
        (item) => Math.round(item.plannedCompletionPercentage)
      );

      const chartTwocompletedTasksUntilSelectedMonth = plannedRealComplieance.map(
        (item) => Math.round(item.completedTasksUntilSelectedMonth)
      );

      const chartTworealCompletionPercentage = plannedRealComplieance.map(
        (item) => Math.round(item.realCompletionPercentage)
      );

      this.chartData2 = {
        labels: splittedManagementNames,
        datasets: [
          {
            label: `% programado Enero - ${
              this.months[this.selectedMonth - 1]?.name
            } vs anual`,
            backgroundColor: "#c4c4c4",
            data: chartTwoplannedCompletionPercentage,
            rawAmounts: chartTwotasksUntilSelectedMonth,
          },
          {
            label: `% ejecutado Enero - ${
              this.months[this.selectedMonth - 1]?.name
            } vs anual`,
            backgroundColor: "#00bcd4",
            data: chartTworealCompletionPercentage,
            rawAmounts: chartTwocompletedTasksUntilSelectedMonth,
          },
        ],
      };

      const maxPlanned = chartTwoplannedCompletionPercentage.length
        ? Math.max(...chartTwoplannedCompletionPercentage)
        : 0;
      const maxReal = chartTworealCompletionPercentage.length
        ? Math.max(...chartTworealCompletionPercentage)
        : 0;
      let maxValue = Math.max(maxPlanned, maxReal);

      let roundedMax = (Math.ceil(maxValue / 10) * 10) + 10;

      if (roundedMax < 10) {
        roundedMax = 10;
      }

      this.chartOptions2 = {
        ...this.chartOptions2,
        scales: {
          ...this.chartOptions2.scales,
          y: {
            ...this.chartOptions2.scales?.y,
            max: roundedMax,
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
            },
          },
        },
        plugins: {
          ...this.chartOptions2.plugins,
          customAnnotations: {
            ...this.chartOptions2.plugins.customAnnotations,
            totalAmounts: chartTwoTotalAnnualTasks,
          },
        },
      };

      /**
       *  third chart
       */
      const serviceCompliance = data.DashboardServiceCompliance;
      const serviceNames = serviceCompliance.map((item) => item.serviceName);
      const splittedServiceNames = serviceNames.map((name) =>
        this.autoSplitLabel(name, 13)
      );
      const chartThreeCompletedTasks = serviceCompliance.map(
        (item) => Math.round(item.completedTasks)
      );
      
      const chartThreeCompletionPercentage = serviceCompliance.map(
        (item) => Math.round(item.completionPercentage)
      );      

      this.chartData3 = {
        labels: splittedServiceNames,
        datasets: [
          {
            label: `% cumplimiento Enero - ${this.months[this.selectedMonth - 1]?.name}`,
            backgroundColor: "#00bcd4",
            data: chartThreeCompletionPercentage,
            rawAmounts: chartThreeCompletedTasks,
          },
        ],
      };

      /**
       * fourth chart
       */
      const associateServicesManagement = data.AssociatedServicesToManagement;
      const associateServicesNames = associateServicesManagement.map(
        (item) => item.serviceName
      );
      const splittedAssociateServicesNames = associateServicesNames.map(
        (name) => this.autoSplitLabel(name, 13,true)
      );

      const chartFourthTotalAnnualTasks = associateServicesManagement.map(
        (item) => Math.round(item.totalAnnualTasks)
      );
      
      const chartFourthtasksUntilSelectedMonth = associateServicesManagement.map(
        (item) => Math.round(item.tasksUntilSelectedMonth)
      );
      
      const chartFourthplannedCompletionPercentage = associateServicesManagement.map(
        (item) => Math.round(item.completionPercentage)
      );
      
      const chartFourthcompletedTasksUntilSelectedMonth = associateServicesManagement.map(
        (item) => Math.round(item.completedTasksUntilSelectedMonth)
      );
      
      const chartFourthrealCompletionPercentage = associateServicesManagement.map(
        (item) => Math.round(item.executionPercentage)
      );
      

      this.chartData4 = {
        labels: splittedAssociateServicesNames,
        datasets: [
          {
            label: `% programado Enero - ${this.months[this.selectedMonth - 1]?.name} vs anual`,
            backgroundColor: "#c4c4c4",
            data: chartFourthplannedCompletionPercentage,
            rawAmounts: chartFourthtasksUntilSelectedMonth,
          },
          {
            label: `% ejecutado ${this.months[this.currentMonth - 1]?.name} - ${
              this.months[this.selectedMonth - 1]?.name
            } vs anual`,
            backgroundColor: "#00bcd4",
            data: chartFourthrealCompletionPercentage,
            rawAmounts: chartFourthcompletedTasksUntilSelectedMonth,
          },
        ],
      };

      const maxPlanned4 = chartFourthplannedCompletionPercentage.length
        ? Math.max(...chartFourthplannedCompletionPercentage)
        : 0;
      const maxReal4 = chartFourthrealCompletionPercentage.length
        ? Math.max(...chartFourthrealCompletionPercentage)
        : 0;
      let maxValue4 = Math.max(maxPlanned4, maxReal4);

      let roundedMax4 = (Math.ceil(maxValue4 / 10) * 10) + 10;

      if (roundedMax4 < 10) {
        roundedMax4 = 10;
      }

      this.chartOptions4 = {
        ...this.chartOptions4,
        scales: {
          ...this.chartOptions4.scales,
          y: {
            ...this.chartOptions4.scales?.y,
            max: roundedMax4,
            beginAtZero: true,
            ticks: {
              callback: (value) => `${value}%`,
            },
          },
        },
        plugins: {
          ...this.chartOptions4.plugins,
          customAnnotations: {
            ...this.chartOptions4.plugins.customAnnotations,
            totalAmounts: chartFourthTotalAnnualTasks,
          },
        },
      };
    },

    addCustomPlugin() {
      const customPlugin = {
        id: "customAnnotations",
        order: 50,
        afterDatasetsDraw(chart) {
          const ctx = chart.ctx;
          const chartArea = chart.chartArea;
          const datasets = chart.data.datasets;
          const totalAmounts =
            chart.options.plugins.customAnnotations.totalAmounts;

          if (!datasets || !Array.isArray(datasets) || datasets.length === 0)
            return;

          datasets.forEach((dataset, datasetIndex) => {
            if (!dataset.rawAmounts || !Array.isArray(dataset.rawAmounts))
              return;

            dataset.rawAmounts.forEach((amount, index) => {
              const meta = chart.getDatasetMeta(datasetIndex);
              const bar = meta.data[index];
              if (!bar) return;

              const porcentaje = dataset.data[index];

              if (!porcentaje || porcentaje === 0) {
                return;
              }

              const isMultiDataset = datasets.length > 1;

              let circleColor = isMultiDataset 
                ? (datasetIndex === 0 ? "#FFC107" : "#FF5722")
                : "#FF5722";

              const circleRadius = 20;
              const x = bar.x;
              const circleY = chartArea.bottom - circleRadius + 7;

              ctx.save();
              ctx.beginPath();
              ctx.arc(x, circleY, circleRadius, 0, 2 * Math.PI);
              ctx.fillStyle = circleColor;
              ctx.fill();

              ctx.fillStyle = "#fff";
              ctx.font = "bold 18px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(amount, x, circleY);
              ctx.restore();
            });

            if (!totalAmounts || !Array.isArray(totalAmounts)) return;

            totalAmounts.forEach((total, index) => {
              const meta = chart.getDatasetMeta(0);
              const bar = meta.data[index];
              const x = bar.x - bar.width * 0.45;
              const y = chartArea.bottom +40;
              const width = bar.width * 2;
              const height = 30;

              ctx.save();
              ctx.beginPath();
              ctx.rect(x, y - 40, width, height);
              ctx.fillStyle = "#3f51b5";
              ctx.fill();
              ctx.fillStyle = "#ffffff";
              ctx.font = "bold 18px Arial";
              ctx.textAlign = "center";
              ctx.fillText(total, x + bar.width, y - 20);
              ctx.restore();
            });
          });
        },
      };
      Chart.register(customPlugin);

      const whiteBackgroundPlugin = {
        id: "whiteBackground",
        beforeDraw(chart) {
          const { ctx, chartArea } = chart;
          ctx.save();
          ctx.fillStyle = "#FFFFFF";
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
          ctx.restore();
        },
      };
      Chart.register(whiteBackgroundPlugin);
    },
    redirectToDetail() {
      this.$router.push({
        name: "ReportActionPlans",
        query: {
          month: this.selectedMonth,
          management_id: this.selectedManagement,
        },
      });
    },

    updateCharts() {
      this.loadData();
    },

    updateManagementType() {
      if (this.selectedManagementType && this.allManagementsState) {
        const filterManagements = this.allManagementsState.filter(
          (ccu) => ccu.management_type === this.selectedManagementType
        );
        this.filteredManagements = filterManagements;

        if (filterManagements.length) {
          this.selectedManagement = filterManagements[0].id;
        }
      }
      this.updateCharts();
    },

    updateURL() {
      if (this.$route.name === "DashboardActionPlan") {
        this.$router
          .push({
            query: {
              selectedMonth: this.selectedMonth,
              selectedManagement: this.selectedManagement || undefined,
            },
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      }
    },

    findValueMonth(monthSelected) {
      if (monthSelected) {
        const data = this.months.find((month) => month.value === monthSelected);
        return data;
      } else {
        return "-";
      }
    },

    autoSplitLabel(label, chunkSize = 13, lineBreak = false) { 
      if (!label) return [];
    
      const parts = lineBreak ? ["", ""] : [];
    
      for (let i = 0; i < label.length; i += chunkSize) {
        parts.push(label.slice(i, i + chunkSize));
      }
    
      return parts;
    },

    async downloadChart(refName) {
      const chartComponent = this.$refs[refName];
      if (!chartComponent) {
        showSnackBar(`No existe la referencia ${refName}`, "error");
        return null;
      }
    
      const canvas = chartComponent.$el;
      if (!canvas) {
        showSnackBar(`No se encontró el canvas dentro del componente.`, "error");
        return null;
      }
    
      return new Promise((resolve) => {
        const originalWidth = canvas.width;
        const originalHeight = canvas.height;
        const scaleFactor = window.devicePixelRatio || 2;
    
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = originalWidth * scaleFactor;
        tempCanvas.height = originalHeight * scaleFactor;
        const ctx = tempCanvas.getContext("2d");
    
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    
        ctx.scale(scaleFactor, scaleFactor);
        ctx.drawImage(canvas, 0, 0, originalWidth, originalHeight);
    
        tempCanvas.toBlob((blob) => {
          resolve({ name: `${refName}.png`, blob });
        }, "image/png");
      });
    },
  
    async downloadAllChartsAsZip() {
      const chartRefs = ["chart1", "chart2", "chart3", "chart4"];
      const chartTitles = [
        this.chartTitle1,
        this.chartTitle2,
        this.chartTitle3,
        this.chartTitle4,
      ];
    
      const zip = new JSZip();
    
      const images = await Promise.all(
        chartRefs.map(async (ref) => {
          return await this.downloadChart(ref);
        })
      );
    
      images.forEach((file, index) => {
        if (file) {
          const formattedTitle = chartTitles[index]
            .replace(/\s+/g, "_")
            .replace(/[^\w-]/g, "");
    
          zip.file(`graficos/${formattedTitle}.png`, file.blob);
        }
      });
    
      const zipFileName = `graficosClimaLaboral_${moment().format(
        "YYYY_MM_DD_HH_mm"
      )}.zip`;
    
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, zipFileName);
      });
    }
  },
  components: {
    BarChart: Bar,
  },
};
