<template>
	<ApproverSettingsSatisfaction />
</template>
<script>

import ApproverSettingsSatisfaction from '@/components/satisfaction-action-plans/approver-settings-satisfaction/index.vue'

export default {
	name: 'ApproverSettingsSatisfactionPage',
	components: { ApproverSettingsSatisfaction },
};
</script>
