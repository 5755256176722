import NotificationActionPlanPendingTab from '@/components/action-plans/components/notification-action-plan-pending-tab/index.vue';
import NotificationActionPlanAcceptTab from '@/components/action-plans/components/notification-action-plan-accept-tab/index.vue';
import NotificationActionPlanDeclineTab from '@/components/action-plans/components/notification-action-plan-decline-tab/index.vue';

export default {
    name: 'ListActionPlanNotification',
    components: {
        NotificationActionPlanPendingTab,
        NotificationActionPlanAcceptTab,
        NotificationActionPlanDeclineTab
    },

    data() {
        return {
            tab: 0,
            allRequest: false,
        };
    },

    computed: {
        breadcrumbs() {
            return [
                {
                    text: "Inicio",
                    link: true,
                    exact: true,
                    disabled: false,
                    to: {
                        name: "Dashboard",
                    },
                },
                {
                    text: "Notificaciones",
                    link: true,
                    exact: true,
                    disabled: true,
                    to: {
                        //   name: "DashboardActionPlan",
                    },
                },
            ];
        },
    },

    methods: {

        isAllowed(module, page, activityName) {

            const activities = this.$store.state.auth.activities || [];
            const found = activities.some(
              (activity) =>
                activity.module_name === module &&
                activity.page_name === page &&
                activity.activity_name === activityName
            );

            this.allRequest = found;

            return found;
          },
          
          
    },

    created() {
    },

    mounted() {
        this.isAllowed('climaLaboral','listaSolicitudesActualizacion','listarTodasSolicitudesActualizacion')
    },

    beforeDestroy() {
    },
};