<template>
	<ApproverSettingsDinamic />
</template>
<script>

import ApproverSettingsDinamic from '@/components/action-plan-dinamics/approver-settings-dinamic/index.vue'

export default {
	name: 'ApproverSettingsDinamicPage',
	components: { ApproverSettingsDinamic },
};
</script>
