import { mapActions, mapState } from "vuex";
import { showSnackBar } from "@/helpers/globalHelpers";
export default {
  name: "ApproverSettingsActionPlan",
  components: {},
  data() {
    return {
      searchText: "",
      loadingData: true,
      existingApproverData:null,
      hasExistingApprovers: false,
      loadingSend:false,
      isLoadingUsers:false,
      isLoadingManagements:false,
      isLoadingCostCenters:false,
      selectedService: null,
      selectedOfficialApprover: null,
      selectedOptionalService: null,
      selectedOptionalApprovers: [],
      filterService: null,
      approversList: [],
      headers: [
        { text: "Servicio", sortable:false,value: "service_name" },
        { text: "Usuario", sortable:false,value: "full_name" },
        { text: "Tipo de Aprobador", sortable:false,value: "role" },
        { text: "Acciones", value: "actions", sortable: false }
      ],
      selectedManagement: null,
      selectedOfficialManagementApprover: null,
      selectedOptionalManagementApprovers: [],
      managementApproversList: [],
      headersManagement: [
        { text: "Gerencia", sortable:false, value: "management_name" },
        { text: "Usuario", sortable:false, value: "full_name" },
        { text: "Tipo de Aprobador", sortable:false, value: "role" },
        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Tipos de plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },

    ...mapState("security", [
      "usersActive",
      "costCenters",
      "allManagementsState"
    ]),

    availableServices() {
      return this.costCenters.filter(service => 
        !this.approversList.some(a => a.service_id === service.id)
      );
    },

    selectedServices() {
      return this.approversList.map(a => ({
        id: a.service_id,
        name: this.getServiceName(a.service_id)
      }));
    },

    filteredApprovers() {
      if (!this.filterService) {
        return this.flattenedApprovers;
      }
      return this.flattenedApprovers.filter(item => item.service_id === this.filterService);
    },

    flattenedApprovers() {
      return this.approversList.flatMap(approver => {
        let officialUser = this.usersActive.find(user => user.id === approver.official_approver);
        let officialEntry = officialUser
          ? {
              service_id: approver.service_id,
              service_name: this.getServiceName(approver.service_id),
              user_id: officialUser.id,
              full_name: officialUser.fullName,
              role: "Oficial"
            }
          : null;

        let optionalEntries = approver.optional_approvers.map(userId => {
          let optionalUser = this.usersActive.find(user => user.id === userId);
          return optionalUser
            ? {
                service_id: approver.service_id,
                service_name: this.getServiceName(approver.service_id),
                user_id: optionalUser.id,
                full_name: optionalUser.fullName,
                role: "Opcional"
              }
            : null;
        });

        return [officialEntry, ...optionalEntries].filter(entry => entry !== null);
      });
    },

    availableManagements() {
      return this.allManagementsState.filter(
        mgmt => !this.managementApproversList.some(a => a.management_id === mgmt.id)
      );
    },

    hasOfficialManagement() {
      return this.managementApproversList.some(a => a.role === "Oficial");
    },

    filteredManagementApprovers() {
      return this.managementApproversList.map(approver => ({
        management_id: approver.management_id,
        management_name: this.getManagementName(approver.management_id),
        user_id: approver.user_id,
        full_name: this.getUserName(approver.user_id),
        role: approver.role
      }));
    }

  },
  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenter",
      "allManagements",
    ]),

    ...mapActions("plan_type", [
      "createModificationApprovers",
      "listAllModificationApprovers",
      "editModificationApprover"
    ]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadCostCenter: async function () {
      this.isLoadingCostCenters = true;
      await this.getCostCenter();
      this.isLoadingCostCenters = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.allManagements();
      this.isLoadingManagements = false;
    },

    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },

    async loadExistingApprovers() {
      try {
        this.loadingData = true;

        const planTypeId = this.$route.params.planTypeId;
        if (!planTypeId) {
          this.loadingData = false;
          return;
        }

        const { ok, response } = await this.listAllModificationApprovers({ plan_type_id: planTypeId });

        if (ok && response?.data?.length) {
          this.existingApproverData = response.data[0];
          this.hasExistingApprovers = true;
          this.populateApprovers(response.data);
        }

      } catch (error) {
        console.error("Error cargando los aprobadores existentes:", error);
      }finally {
        this.loadingData = false;
      }
    },

    async registerApprovers() {
      try {
        this.loadingSend = true;
  
        const planTypeId = this.$route.params.planTypeId;
  
        if (!planTypeId) {
          showSnackBar("Error: No se encontró el ID del tipo de plan.", "error");
          this.loadingSend = false;
          return;
        }
  
        const payload = {
          plan_type_id: Number(planTypeId),
          modification_approver_management: this.managementApproversList.reduce((acc, approver) => {
            if (approver.role === "Oficial") {
              acc.push({
                management_id: approver.management_id,
                official_approver: approver.user_id,
                optional_approvers: this.managementApproversList
                  .filter(a => a.management_id === approver.management_id && a.role === "Opcional")
                  .map(a => a.user_id)
              });
            }
            return acc;
          }, []),
          modification_approver_service: this.approversList.map(approver => ({
            service_id: approver.service_id,
            official_approver: approver.official_approver,
            optional_approvers: approver.optional_approvers
          }))
        };
  
        if (payload.modification_approver_management.length === 0) {
          showSnackBar("Debe agregar al menos un aprobador de gerencia antes de actualizar.", "error");
          this.loadingSend = false;
          return;
        }
        
        if (payload.modification_approver_service.length === 0) {
          showSnackBar("Debe agregar al menos un aprobador de servicio antes de actualizar.", "error");
          this.loadingSend = false;
          return;
        }
  
        const { ok, error } = await this.createModificationApprovers(payload);
  
        if (ok) {
          showSnackBar("Aprobadores registrados correctamente.", "success");
          this.$router.push({ name: "ListPlanType" });
        } else {
          let errorMsm = error?.data?.message;
          errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
          showSnackBar(errorMsm, "error");
        }
  
      } catch (err) {
        showSnackBar("Ocurrió un error inesperado.", "error");
        console.error("Error en registerApprovers:", err);
      } finally {
        this.loadingSend = false;
      }
    },
    
    async updateApprovers() {
      try {
        this.loadingSend = true;
    
        if (!this.existingApproverData || !this.existingApproverData.id) {
          showSnackBar("Error: No se encontró el ID del registro a actualizar.", "error");
          this.loadingSend = false;
          return;
        }
        
        const approverId = this.existingApproverData.id;
    
        const payload = {
          modification_approver_management: this.managementApproversList.reduce((acc, approver) => {
            if (approver.role === "Oficial") {
              acc.push({
                management_id: approver.management_id,
                official_approver: approver.user_id,
                optional_approvers: this.managementApproversList
                  .filter(a => a.management_id === approver.management_id && a.role === "Opcional")
                  .map(a => a.user_id)
              });
            }
            return acc;
          }, []),
          modification_approver_service: this.approversList.map(approver => ({
            service_id: approver.service_id,
            official_approver: approver.official_approver,
            optional_approvers: approver.optional_approvers
          }))
        };
        
        if (payload.modification_approver_management.length === 0) {
          showSnackBar("Debe agregar al menos un aprobador de gerencia antes de actualizar.", "error");
          this.loadingSend = false;
          return;
        }
        
        if (payload.modification_approver_service.length === 0) {
          showSnackBar("Debe agregar al menos un aprobador de servicio antes de actualizar.", "error");
          this.loadingSend = false;
          return;
        }
        
        const { ok, error } = await this.editModificationApprover({
          id: approverId,
          payload
        });
    
        if (ok) {
          showSnackBar("Aprobadores registrados correctamente.", "success");
          window.location.reload();
        } else {
          let errorMsm = error?.data?.message;
          errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
          showSnackBar(errorMsm, "error");
        }
    
      } catch (err) {
        showSnackBar("Ocurrió un error inesperado al actualizar.", "error");
        console.error("Error en updateApprovers:", err);
      } finally {
        this.loadingSend = false;
      }
    },

    populateApprovers(data) {
      const approvers = data[0];
    
      if (approvers?.management_approver) {
        this.managementApproversList = [
          {
            management_id: approvers.management_approver.management_id,
            user_id: approvers.management_approver.user_id,
            full_name: this.getUserName(approvers.management_approver.user_id),
            role: "Oficial"
          },
          ...approvers.management_approver.children.map(child => ({
            management_id: child.management_id,
            user_id: child.user_id,
            full_name: this.getUserName(child.user_id),
            role: "Opcional"
          }))
        ];
      }
    
      if (approvers?.service_approver) {
        this.approversList = [
          {
            service_id: approvers.service_approver.service_id,
            official_approver: approvers.service_approver.user_id,
            optional_approvers: approvers.service_approver.children.map(child => child.user_id)
          }
        ];
      }
    },

    getServiceName(serviceId) {
      let service = this.costCenters.find(c => c.id === serviceId);
      return service ? service.name : "Desconocido";
    },
  
    addOfficialApprover() {
      if (!this.selectedService || !this.selectedOfficialApprover) {
        showSnackBar("Debe seleccionar un servicio y un usuario aprobador oficial.", "warning");
        return;
      }
    
      this.approversList.push({
        service_id: this.selectedService,
        official_approver: this.selectedOfficialApprover,
        optional_approvers: []
      });
    
      this.selectedService = null;
      this.selectedOfficialApprover = null;
    },
    
    addOptionalApprovers() {
      if (!this.selectedOptionalService || this.selectedOptionalApprovers.length === 0) {
        showSnackBar("Debe seleccionar un servicio y al menos un usuario opcional.", "warning");
        return;
      }
    
      let officialApprover = this.approversList.find(
        item => item.service_id === this.selectedOptionalService
      );
    
      if (!officialApprover) {
        showSnackBar("Debe agregar primero un aprobador oficial para este servicio.", "warning");
        return;
      }
    
      officialApprover.optional_approvers = [
        ...new Set([...officialApprover.optional_approvers, ...this.selectedOptionalApprovers])
      ];
    
      this.selectedOptionalService = null;
      this.selectedOptionalApprovers = [];
    },
  
    confirmRemoveApprover(item) {
      if (item.role === "Oficial") {
        this.$swal.fire({
          title: "¿Estás seguro?",
          text: "Se eliminará solo el usuario oficial, los opcionales se mantendrán.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.isConfirmed) {
            this.removeApprover(item);
          }
        });
      } else {
        this.$swal.fire({
          title: "¿Eliminar aprobador opcional?",
          text: "Se eliminará solo este usuario opcional.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.isConfirmed) {
            this.removeApprover(item);
          }
        });
      }
    },
  
    removeApprover(item) {
      // if (item.role === "Oficial") {
      //   this.approversList = this.approversList.filter(a => a.service_id !== item.service_id);
      // } else {
      //   let approverEntry = this.approversList.find(a => a.service_id === item.service_id);
      //   if (approverEntry) {
      //     approverEntry.optional_approvers = approverEntry.optional_approvers.filter(
      //       id => id !== item.user_id
      //     );
      //   }
      // }

      if (item.role === "Oficial") {
        let index = this.approversList.findIndex(a => a.service_id === item.service_id);
        if (index !== -1) {
          let approverEntry = this.approversList[index];
          if (approverEntry.optional_approvers && approverEntry.optional_approvers.length > 0) {
            approverEntry.official_approver = approverEntry.optional_approvers[0];
            approverEntry.optional_approvers.splice(0, 1);
          } else {
            this.approversList.splice(index, 1);
          }
        }
      } else {
        let approverEntry = this.approversList.find(a => a.service_id === item.service_id);
        if (approverEntry) {
          approverEntry.optional_approvers = approverEntry.optional_approvers.filter(
            id => id !== item.user_id
          );
          if (!approverEntry.official_approver && approverEntry.optional_approvers.length === 0) {
            this.approversList = this.approversList.filter(a => a.service_id !== item.service_id);
          }
        }
      }
    },

    getManagementName(managementId) {
      let management = this.allManagementsState.find(m => m.id === managementId);
      return management ? management.name : "Desconocido";
    },

    addOfficialManagementApprover() {
      if (!this.selectedManagement || !this.selectedOfficialManagementApprover) {
        showSnackBar("Debe seleccionar una gerencia y un usuario aprobador oficial.", "warning");
        return;
      }
    
      let officialUser = this.usersActive.find(user => user.id === this.selectedOfficialManagementApprover);
      let officialFullName = officialUser ? officialUser.fullName : "Desconocido";
    
      this.managementApproversList.push({
        management_id: this.selectedManagement,
        management_name: this.getManagementName(this.selectedManagement),
        user_id: this.selectedOfficialManagementApprover,
        full_name: officialFullName,
        role: "Oficial"
      });
    
      this.selectedManagement = null;
      this.selectedOfficialManagementApprover = null;
    },
    
    addOptionalManagementApprovers() {
      if (this.selectedOptionalManagementApprovers.length === 0) {
        showSnackBar("Debe seleccionar al menos un usuario opcional.", "warning");
        return;
      }
    
      let officialEntry = this.managementApproversList.find(a => a.role === "Oficial");
    
      if (!officialEntry) {
        showSnackBar("Debe agregar primero un aprobador oficial para esta gerencia.", "warning");
        return;
      }
    
      this.selectedOptionalManagementApprovers.forEach(userId => {
        let optionalUser = this.usersActive.find(user => user.id === userId);
        let optionalFullName = optionalUser ? optionalUser.fullName : "Desconocido";
    
        this.managementApproversList.push({
          management_id: officialEntry.management_id,
          management_name: officialEntry.management_name,
          user_id: userId,
          full_name: optionalFullName,
          role: "Opcional"
        });
      });
    
      this.selectedOptionalManagementApprovers = [];
    },

    confirmRemoveManagementApprover(item) {
      if (item.role === "Oficial") {
        this.$swal.fire({
          title: "¿Estás seguro?",
          text: "Se eliminará solo este aprobador oficial; los opcionales de esta gerencia se mantendrán.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.isConfirmed) {
            this.removeManagementApprover(item);
          }
        });
      } else {
        this.$swal.fire({
          title: "¿Eliminar aprobador opcional?",
          text: "Solo se eliminará este usuario opcional.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar"
        }).then(result => {
          if (result.isConfirmed) {
            this.removeManagementApprover(item);
          }
        });
      }
    },

    removeManagementApprover(item) {
      // if (item.role === "Oficial") {
      //   this.managementApproversList = this.managementApproversList.filter(
      //     a => a.management_id !== item.management_id
      //   );
      // } else {
      //   this.managementApproversList = this.managementApproversList.filter(
      //     a => !(a.management_id === item.management_id && a.user_id === item.user_id)
      //   );
      // }

      if (item.role === "Oficial") {
        this.managementApproversList = this.managementApproversList.filter(
          a => !(a.management_id === item.management_id && a.user_id === item.user_id && a.role === "Oficial")
        );
        const optional = this.managementApproversList.filter(
          a => a.management_id === item.management_id && a.role === "Opcional"
        );

        if (optional.length > 0) {
          optional[0].role = "Oficial";
          optional[0].is_official = true;
        }

      } else {
        this.managementApproversList = this.managementApproversList.filter(
          a => !(a.management_id === item.management_id && a.user_id === item.user_id && a.role === "Opcional")
        );
      }
    },

    getUserName(userId) {
      let user = this.usersActive.find(u => u.id === userId);
      return user ? user.fullName : "Desconocido";
    },

    customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},

    clearSearchText() {
      this.searchText = "";
    },

  },
  created() {
    this.loadUsers();
    this.loadCostCenter();
    this.loadManagements();
    this.loadExistingApprovers();
  },
  beforeDestroy() {},
};
