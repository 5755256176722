import { showSnackBar } from "@/helpers/globalHelpers";
import { mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      loadingHeadersExportReport: false,
      reportName: "",
      reportDescription: "",
      selectedColumns: [],
      columns: null,
      year: new Date().getFullYear(),
      years: [2023, 2024, 2025, 2026],
      rules: {
        required: (value) => !!value || "Este campo es obligatorio",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    translatedColumns() {
      return this.columns
        ? this.columns.map((col) => ({
            text: this.translateHeader(col),
            value: col,
          }))
        : [];
    },
  },

  created() {
    this.headersExportReport();
  },

  methods: {
    ...mapActions("evidence_action_plan", [
      "getHEadersExportReport",
      "registerExportReportActionPlan",
    ]),

    closeModal() {
      this.show = false;
    },

    async headersExportReport() {
      this.loadingHeadersExportReport = true;
      try {
        const { planTypeId } = this.$route.params;
        const { error, response, ok } = await this.getHEadersExportReport(
          planTypeId
        );

        if (error) {
          showError(error);
        } else if (ok) {
          this.columns = response;
        }
      } catch (err) {
        showError("Ocurrió un error inesperado al obtener los encabezados.");
      } finally {
        this.loadingHeadersExportReport = false;
      }
    },

    async saveReport() {
        this.loading = true;
        try {
          const { planTypeId } = this.$route.params;
          const data = {
            year: this.year,
            plan_type_id: parseInt(planTypeId),
            name: this.reportName,
            description: this.reportDescription,
            selectedColumns: this.selectedColumns,
          };
  
          const { error } = await this.registerExportReportActionPlan(data);
          if (error) {
            showError(error);
          } else {
            this.$emit("report-saved");
            this.closeModal();
          }
        } catch (error) {
          showSnackBar("Error al guardar el informe.", "error");
        } finally {
          this.loading = false;
        }
      },

    translateHeader(headerName) {
      const staticHeaderMap = {
        id: "ID de Evidencia",
        register_date: "Fecha de subida de evidencia",
        action_plan_description: "Descripción",
        recipients: "Usuarios a Notificar",
        service: "Servicio",
        action_plan_details: "Preguntas",
        satisfaction_factors: "Factores",
      };

      return staticHeaderMap[headerName] || headerName;
    },
  },
};
